import React from 'react'
import { rgba } from 'polished'
import { useRouter } from 'next/router'

import { colors } from '@/styles/theme'

interface Props {
  value: number
  prefix: string
}

function Star(props: Props) {
  const { value, prefix } = props
  const stopValue = `${value * 100}%`
  const router = useRouter()
  const color =
    router.query.slug === 'legia-warszawa' ? '#39c349' : colors.greenishCyan

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 40">
      <defs>
        <linearGradient id={`Gradient_${prefix}`} x1="0" x2="1" y1="0" y2="0">
          <stop offset="0%" stopColor={color} />
          <stop offset={stopValue} stopColor={color} />
          <stop offset={stopValue} stopColor={rgba(colors.paleGrey, 0.33)} />
          <stop offset="100%" stopColor={rgba(colors.paleGrey, 0.33)} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={`url(#Gradient_${prefix})`}
          d="M289.89 15.116c-.274-.846-1.029-1.446-1.921-1.526l-12.123-1.094-4.793-11.15C270.7.53 269.895 0 269 0c-.894 0-1.7.529-2.053 1.348l-4.793 11.148-12.124 1.094c-.891.082-1.644.68-1.92 1.526-.277.845-.021 1.772.653 2.356l9.163 7.986-2.702 11.829c-.198.87.142 1.768.868 2.29.39.28.847.423 1.307.423.397 0 .791-.106 1.145-.317L269 33.473l10.453 6.21c.765.458 1.73.416 2.454-.106.726-.523 1.065-1.422.868-2.29l-2.702-11.829 9.163-7.984c.674-.586.93-1.512.654-2.358"
          transform="translate(-494.000000, -3722.000000) translate(0.000000, 2725.000000) translate(136.000000, 929.000000) translate(110.000000, 68.000000)"
        />
      </g>
    </svg>
  )
}

export default Star
