import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'
import { colors } from '@/styles/theme'

export const HintsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 9px 0 0;
  justify-content: center;
  gap: 4px;
  margin-bottom: 80px;

  ${mediaUp('lg')} {
    margin: 20px 0 0;
    gap: 8px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

export const HintsListItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`

export const HintsListItemLink = styled.a`
  color: #3586e6;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid ${colors.twilightBlue};
  }

  ${mediaUp('lg')} {
    font-size: 18px;
  }
`

export const Dot = styled.img`
  margin: 0 8px;

  ${mediaUp('lg')} {
    margin: 0 16px;
  }
`
