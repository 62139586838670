import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'
import { colors } from '@/styles/theme'
import { Text1, Text2, LegiaBlack } from '@/styles/typography'

export const Container = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${props => !props.noMargin && 'margin-bottom: 40px;'}

  ${mediaUp('md')} {
    margin-bottom: 0;
    margin-top: -0.3em;
  }
`

export const Title = styled(Text1)`
  color: ${colors.white};
  text-align: center;

  .legia-warszawa & {
    ${LegiaBlack};
  }
`

export const Grade = styled(Text2)`
  color: ${colors.white};

  .legia-warszawa & {
    ${LegiaBlack};
  }
`

export const StarsWrapper = styled.div`
  margin: 18px 0 10px;

  ${mediaUp('lg')} {
    margin: 40px 0 20px;
  }
`
