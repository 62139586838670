import styled from '@emotion/styled'

import { mediaColumns } from '@/styles/grid'

export const StarsWrapper = styled.div`
  ${mediaColumns({ xs: [8, 2], md: [4, 1] })};
  align-self: center;
`

export const OpinionsWrapper = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [6, 6] })};
  width: 100%;
`
