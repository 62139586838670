import { useTranslation } from 'next-i18next'
import { css } from '@emotion/react'

import Link from '@/components/common/Link'
import appleDark from '@/assets/apple-dark.svg'
import appleLight from '@/assets/apple-light.svg'

import { LinkWrapper } from './AppleButton.styled'

interface Props {
  dark?: boolean
}

function AppleButton(props: Props) {
  const { dark = false } = props
  const { t } = useTranslation('common')

  return (
    <Link href="https://apps.apple.com/pl/app/navipay/id1455350141" passHref>
      <LinkWrapper
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label={t('a11y.appleButton')}
      >
        {dark ? (
          <img src={appleDark} css={imageStyle} />
        ) : (
          <img src={appleLight} css={imageStyle} />
        )}
      </LinkWrapper>
    </Link>
  )
}

const imageStyle = css`
  width: 100%;
  height: 100%;
`

export default AppleButton
