import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${props => (props.small ? '0 3px' : '0 6px')};
  width: ${props => (props.small ? '10px' : '25px')};
  height: ${props => (props.small ? '10px' : '25px')};

  ${mediaUp('lg')} {
    margin: ${props => (props.small ? '0 5px' : '0 10px')};
    width: ${props => (props.small ? '19px' : '42px')};
    height: ${props => (props.small ? '19px' : '42px')};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`
