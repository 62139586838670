import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { rgba } from 'polished'

import { Grid, mediaColumns } from '@/styles/grid'
import { vars, colors } from '@/styles/theme'
import { mediaUp, mediaDown } from '@/styles/media'
import { fade } from '@/styles/global'
import { gradientApla } from '@/styles/mixins'

interface ContainerProps {
  heroHome: boolean
  aplaSepiaColor?: boolean
}

export const Container = styled.section<ContainerProps>`
  ${gradientApla};
  position: relative;
  background-color: ${colors.paleGrey};

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;

    ${mediaUp('md')} {
      height: 100%;
    }
  }

  ${({ heroHome }) =>
    heroHome &&
    css`
      ${NoMobileGradient};
    `}

  ${({ aplaSepiaColor }) =>
    aplaSepiaColor &&
    css`
      ${AplaSepiaColor};
    `}
`

export const GridWrapper = styled(Grid)`
  position: relative;
  z-index: 3;
  padding-top: ${vars.headerHeightMobile};

  ${mediaUp('lg')} {
    padding-top: ${vars.headerHeight};
  }
`

export const ContentWrapper = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [12, 0] })};
  position: relative;
  padding: 10px 0 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  ${mediaUp('md')} {
    animation: ${fade} 1s both;
    animation-delay: 0.75s;
    padding: 20px 0 60px;
  }

  ${mediaUp('lg')} {
    padding: 20px 0 80px;
  }
`

export const NoMobileGradient = css`
  &::after {
    ${mediaDown('md')} {
      content: none;
    }
  }
`

export const AplaSepiaColor = css`
  background-color: #eed4b4;

  p > span {
    color: ${colors.darkSkyBlue};

    br {
      display: block;
    }
  }

  &::after {
    content: '';
    background: #eed4b4;
    background: linear-gradient(
      180deg,
      ${rgba('#eed4b4', 1)} 0%,
      ${rgba('#eed4b4', 1)} 65%,
      ${rgba('#eed4b4', 0)} 100%
    );

    ${mediaUp('md')} {
      background: linear-gradient(
        90deg,
        ${rgba('#eed4b4', 1)} 0%,
        ${rgba('#eed4b4', 1)} 35%,
        ${rgba('#eed4b4', 0)} 80%
      );

      html[lang^='ar'] & {
        background: linear-gradient(
          -90deg,
          ${rgba('#eed4b4', 1)} 0%,
          ${rgba('#eed4b4', 1)} 35%,
          ${rgba('#eed4b4', 0)} 80%
        );
      }
    }
  }
`
