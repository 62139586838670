import React from 'react'

import Link from '@/components/common/Link'

import { City } from '@/service'
import dotSVG from '@/assets/dot.svg'
import { useIsMobile } from '@/utils/useMobile'
import Spinner from '@/components/common/SpinnerPage'

import {
  HintsList,
  FlexContainer,
  HintsListItem,
  HintsListItemLink,
  Dot,
  Break
} from './SearchHinsMainPage.styled'

interface Props {
  cities?: City[]
}

const SearchHintsMainPage = (props: Props) => {
  const { cities } = props
  const { isMobile } = useIsMobile()

  const itemsPerRow = isMobile ? 4 : 6
  if (!cities) {
    return <Spinner />
  }
  const calculateRows = (idx: number) =>
    idx < cities.length - 1 && (idx + 1) % itemsPerRow !== 0

  return (
    <HintsList>
      {cities?.map((el, idx) => (
        <React.Fragment key={idx}>
          <FlexContainer>
            <HintsListItem data-test-id={el.cityUrlSlug}>
              <Link
                href={{
                  pathname: '/parkings/[city]',
                  query: { city: el.cityUrlSlug }
                }}
                passHref
                prefetch={false}
              >
                <HintsListItemLink>{el.label}</HintsListItemLink>
              </Link>
            </HintsListItem>
            {calculateRows(idx) && <Dot src={dotSVG} alt="dot" />}
          </FlexContainer>
          {(idx + 1) % itemsPerRow === 0 && <Break />}
        </React.Fragment>
      ))}
    </HintsList>
  )
}

export default SearchHintsMainPage
