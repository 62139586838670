import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { fade } from '@/styles/global'
import { mediaUp, mediaDown } from '@/styles/media'

export const Lead = styled.div`
  margin: 14px 0 18px;

  ${mediaDown('md')} {
    max-width: 348px;

    br {
      display: none;
    }
  }

  ${mediaUp('lg')} {
    margin: 30px 0 40px;
  }
`

export const Background = styled.picture`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  z-index: 1;

  ${mediaUp('md')} {
    animation: ${fade} 1s both;
    animation-delay: 0.5s;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${mediaUp('md')} {
    left: 57%;
    width: 43%;
    height: 100%;

    html[lang^='ar'] & {
      right: 34%;
      left: unset;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(242, 246, 253, 1) 0%,
        rgba(242, 246, 253, 1) 15%,
        rgba(242, 246, 253, 0) 100%
      );
      pointer-events: none;
      z-index: 2;
    }
  }
`

export const Animation = css`
  ${mediaUp('md')} {
    animation: ${fade} 0.75s both;
    animation-delay: 0.25s;
  }
`

export const OptionsWrapper = styled.div`
  padding: 24px 0;

  ${mediaUp('md')} {
    padding: 64px 0 32px 0;
  }
`
