import { useTranslation } from 'next-i18next'

import { Fade } from 'react-awesome-reveal'

import Title from '@/components/common/Title'
import Results from '@/components/parkings/Results'
import { Grid } from '@/styles/grid'

import { Parking } from '@/service'

import { useFetchParkings } from '@/service/apiHooks/useFetchParkings'

import { useMarket } from '@/utils/multi-markets/context'

import Spinner from '../../common/SpinnerPage'

import { Wrapper, TitleWrapper } from './Discover.styled'

const Discover = () => {
  const { t } = useTranslation('common')
  const { locale, market } = useMarket()
  const { parkings, isLoading } = useFetchParkings({
    params: {
      lang: locale,
      size: 70,
      countryCode: market,
      sorting: 'distance;asc'
    }
  })
  return (
    <Fade triggerOnce>
      <Wrapper id="discover-section">
        <Grid>
          <TitleWrapper>
            <Title text={t('discover')} />
          </TitleWrapper>
        </Grid>
        {!isLoading && parkings?.length ? (
          <Results parkings={parkings as Parking[]} />
        ) : (
          <Spinner />
        )}
      </Wrapper>
    </Fade>
  )
}

export default Discover
