import styled from '@emotion/styled'

import { colors } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { mediaColumns } from '@/styles/grid'

import Title from '@/components/common/Title'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.darkSkyBlue};
  padding: 40px 0;

  ${mediaUp('lg')} {
    padding: 80px 0;
  }
`

export const TitleWrapper = styled(Title)`
  ${mediaColumns({ xs: [12, 0] })};

  ${mediaUp('md')} {
    br {
      display: none;
    }
  }
`
