import React from 'react'
import parse from 'html-react-parser'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'next-i18next'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { css } from '@emotion/react'

import StarsComponent from '@/components/common/Stars'

import { mediaUp } from '@/styles/media'
import { colors, vars, transitions } from '@/styles/theme'
import { Text1, Text2 } from '@/styles/typography'
import { focusVisible } from '@/styles/mixins'
import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'

interface Opinion {
  author: string
  description: string
  stars: string
  title: string
}

function Opinions() {
  const { t } = useTranslation(['opinions', 'common'])

  return (
    <div css={styles.container}>
      <Swiper
        slidesPerView={1}
        loop
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: '.opinions-button-next',
          prevEl: '.opinions-button-prev'
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
      >
        <button
          css={styles.buttonWrapper}
          className="opinions-button-prev"
          aria-label={t('common:a11y.prevSlide')}
        >
          <ChevronLeft />
        </button>
        <button
          css={styles.buttonWrapper}
          className="opinions-button-next"
          aria-label={t('common:a11y.nextSlide')}
        >
          <ChevronRight />
        </button>
        {Array.isArray(t('opinions:list', { returnObjects: true })) &&
          t('opinions:list', { returnObjects: true }).map(
            (el: Opinion, i: number) => (
              <SwiperSlide key={i}>
                <div css={styles.content}>
                  {el?.title && (
                    <Text1 as="strong" css={styles.title}>
                      {el.title}
                    </Text1>
                  )}
                  {el?.description && (
                    <Text2 css={styles.description}>
                      {parse(el.description)}
                    </Text2>
                  )}
                  {el?.author && (
                    <div css={styles.metaWrapper}>
                      <Text2 css={styles.author}>{el.author}</Text2>
                      <div css={styles.stars}>
                        <StarsComponent
                          small
                          grade={el.stars}
                          prefix={`opinions_${el.author.replace(
                            ' ',
                            '_'
                          )}_${i}`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            )
          )}
      </Swiper>
    </div>
  )
}

const styles = {
  container: css`
    .opinions-button-next {
      right: 4px;
    }

    .opinions-button-prev {
      left: 4px;
    }

    .opinions-button-next,
    .opinions-button-prev {
      ${focusVisible};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      height: 40px;
      width: 40px;

      ${mediaUp('lg')} {
        height: 50px;
        width: 50px;
      }

      svg {
        stroke: ${vars.whiteOpacity};
        stroke-width: 2px;
        height: 100%;
        width: 100%;
        transition: stroke ${transitions.base};
      }
    }

    .opinions-button-next:hover svg {
      stroke: ${vars.whiteOpacityActive};
    }

    .opinions-button-prev:hover svg {
      stroke: ${vars.whiteOpacityActive};
    }

    .swiper-slide {
      height: auto;
    }
  `,
  buttonWrapper: css`
    z-index: 1;
  `,
  content: css`
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 50px;

    ${mediaUp('md')} {
      margin: 0 60px;
    }

    ${mediaUp('lg')} {
      margin: 0 75px;
    }
  `,
  title: css`
    color: ${colors.white};
  `,
  description: css`
    color: ${colors.white};
    margin: 14px 0;

    ${mediaUp('lg')} {
      margin: 30px 0;
    }
  `,
  author: css`
    color: ${colors.white};
    margin-bottom: 10px;

    ${mediaUp('lg')} {
      margin: 0;
      margin-inline-end: 5px;
    }
  `,
  stars: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  metaWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaUp('lg')} {
      flex-direction: row;
    }
  `
}

export default Opinions
