import { useMarket } from '@/utils/multi-markets/context'

const background = {
  pl: {
    desktop: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/pl/pl_home-background.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/pl/pl_home-background%402x.webp'
    },
    mobile: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/pl/pl_home-background-mobile.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/pl/pl_home-background-mobile%402x.webp'
    }
  },
  ae: {
    desktop: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/ae/ae_home-background.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/ae/ae_home-background%402x.webp'
    },
    mobile: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/ae/ae_home-background-mobile.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/ae/ae_home-background-mobile%402x.webp'
    }
  },
  bh: {
    desktop: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/bh/bh_home-background.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/bh/bh_home-background%402x.webp'
    },
    mobile: {
      '1x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/bh/bh_home-background-mobile.webp',
      '2x_webp':
        'https://cdn.naviparking.com/digital_parking/hero_image/bh/bh_home-background-mobile%402x.webp'
    }
  }
}

export const useHeroHomeLogic = (t: (key: string, options?: any) => string) => {
  const { market } = useMarket()
  const bg = background[market]
  const heroTitle = t('hero.titleHomepage')

  return { market, bg, heroTitle }
}
