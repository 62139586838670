import { useTranslation } from 'next-i18next'
import { css } from '@emotion/react'

import Link from '@/components/common/Link'

import googleDark from '@/assets/google-dark.svg'
import googleLight from '@/assets/google-light.svg'

import { LinkWrapper } from './GoogleButton.styled'

interface Props {
  dark?: boolean
}

function GoogleButton(props: Props) {
  const { dark = false } = props
  const { t } = useTranslation('common')

  return (
    <Link
      href="https://play.google.com/store/apps/details?id=eu.ekinnolab.navipay"
      passHref
    >
      <LinkWrapper
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label={t('a11y.googleButton')}
      >
        {dark ? (
          <img src={googleDark} alt={t('park.imageAlt')} css={imageStyle} />
        ) : (
          <img src={googleLight} alt={t('park.imageAlt')} css={imageStyle} />
        )}
      </LinkWrapper>
    </Link>
  )
}

const imageStyle = css`
  width: 100%;
  height: 100%;
`

export default GoogleButton
