import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

import StarsWithOpinions from '@/components/sections/StarsWithOpinions'
import CustomReveal from '@/components/common/CustomReveal'

import { Grid } from '@/styles/grid'

import { Container, TitleWrapper } from './Parking.styled'

const Park = dynamic(() => import('@/components/sections/Park'))

function Parking() {
  const { t } = useTranslation('home')

  return (
    <Container data-test-id={'home-navipay'}>
      <CustomReveal cascade>
        <Grid>
          <TitleWrapper text={t('park')} light />
        </Grid>
        <Park dark />
        <StarsWithOpinions />
      </CustomReveal>
    </Container>
  )
}

export default Parking
