import { Grid } from '@/styles/grid'
import Stars from '@/components/sections/Stars'
import Opinions from '@/components/sections/Opinions'
import CustomReveal from '@/components/common/CustomReveal'

import { OpinionsWrapper, StarsWrapper } from './StarsWithOpinions.styled'

function StarsWithOpinions() {
  return (
    <Grid>
      <StarsWrapper>
        <CustomReveal>
          <Stars />
        </CustomReveal>
      </StarsWrapper>
      <OpinionsWrapper>
        <CustomReveal>
          <Opinions />
        </CustomReveal>
      </OpinionsWrapper>
    </Grid>
  )
}

export default StarsWithOpinions
