import React from 'react'

import ScrollArrow from './ScrollArrow'
import {
  Container,
  ContentWrapper,
  GridWrapper
} from './HeroWrapperWithBackground.styled'

interface Props {
  content: React.ReactNode
  images: React.ReactNode
  className?: string
  heroHome?: boolean
  anchor?: string
  dataTestId?: string
  aplaSepiaColor?: boolean
}

function HeroWrapperWithBackground(props: Props) {
  const {
    content,
    images,
    className,
    heroHome = false,
    anchor,
    dataTestId,
    aplaSepiaColor
  } = props

  return (
    <Container
      className={className}
      heroHome={heroHome}
      aplaSepiaColor={aplaSepiaColor}
      {...(dataTestId && { 'data-test-id': dataTestId })}
    >
      <GridWrapper>
        <ContentWrapper>{content}</ContentWrapper>
      </GridWrapper>
      {images}
      {anchor && <ScrollArrow anchor={anchor} />}
    </Container>
  )
}

export default HeroWrapperWithBackground
