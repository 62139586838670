import { useTranslation } from 'next-i18next'
import html from 'html-react-parser'

import StarsComponent from '@/components/common/Stars'

import { Container, Title, Grade, StarsWrapper } from './Stars.styled'

interface Props {
  noMargin?: boolean
}

function Stars(props: Props) {
  const { t } = useTranslation('common')
  const { noMargin = false } = props

  const grade = '4.3'
  return (
    <Container noMargin={noMargin}>
      <Title as="strong">{html(t('park.app'))}</Title>
      <StarsWrapper>
        <StarsComponent grade={grade} prefix="app" />
      </StarsWrapper>
      <Grade>
        {t('park.stars')} {`${grade} / 5`}
      </Grade>
    </Container>
  )
}

export default Stars
