import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { Parking } from '@/service'
import {
  LoadMore,
  ParkingWrap,
  ResultsWrapper,
  Container
} from '@/components/parkings/Results.styled'
import { Grid } from '@/styles/grid'
import ParkingTile from '@/components/common/ParkingTile'
import Button from '@/components/common/Button'

interface Props {
  parkings?: Parking[]
}

function Results(props: Props) {
  const { parkings } = props
  const { t } = useTranslation('common')
  const itemsRepeat = 6
  const [counter, setCounter] = useState(itemsRepeat)

  return (
    <Grid>
      <Container>
        <ResultsWrapper data-test-id={'results-wrapper'}>
          {parkings?.slice(0, counter).map(parking => (
            <ParkingWrap key={parking.uuid}>
              <ParkingTile parking={parking} />
            </ParkingWrap>
          ))}
        </ResultsWrapper>
        {parkings && parkings.length > counter && (
          <LoadMore>
            <Button
              onClick={() => setCounter(counter + itemsRepeat)}
              text={t('results.button')}
              dataTestId={'parkings-tiles-more'}
            />
          </LoadMore>
        )}
      </Container>
    </Grid>
  )
}

export default Results
