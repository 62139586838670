import { css } from '@emotion/react'

import GoogleButton from '@/components/common/GoogleButton'
import AppleButton from '@/components/common/AppleButton'
import StarsComponent from '@/components/parking/Stars'
import { mediaUp } from '@/styles/media'

function Stars() {
  return (
    <div css={styles.container}>
      <StarsComponent noMargin />
      <div css={styles.iconsWrapper}>
        <GoogleButton />
        <AppleButton />
      </div>
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;

    ${mediaUp('md')} {
      margin-bottom: 0;
    }
  `,
  iconsWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 18px;

    svg,
    img {
      width: 87px;
      height: 31px;
    }

    a:first-of-type {
      margin-inline-end: 20px;
    }

    ${mediaUp('lg')} {
      margin-top: 40px;

      svg,
      img {
        width: 170px;
        height: 58px;
      }

      a:first-of-type {
        margin-inline-end: 40px;
      }
    }
  `
}

export default Stars
