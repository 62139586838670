import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import Star from '@/components/icons/Star'

import starUrlDefault from '@/assets/star.svg'
import starUrlLegia from '@/assets/star-legia.svg'

import { Container, Icon } from './Stars.styled'

interface Props {
  grade: string
  prefix: string
  small?: boolean
}

function Stars(props: Props) {
  const { grade, prefix, small = false } = props
  const parsedGrade = Number(grade)
  const router = useRouter()
  const starUrl =
    router.query.slug === 'legia-warszawa' ? starUrlLegia : starUrlDefault

  return (
    <Container>
      {[...Array(5)].map((x, i) =>
        i + 1 < parsedGrade ? (
          <Icon key={i} small={small}>
            <img src={starUrl} css={starIconStyle} />
          </Icon>
        ) : (
          <Icon key={i} small={small}>
            <Star value={parsedGrade - i} prefix={`${prefix}_${i}`} />
          </Icon>
        )
      )}
    </Container>
  )
}

const starIconStyle = css`
  width: 42px;
  height: 40px;
`

export default Stars
