import { css } from '@emotion/react'
import { useTranslation } from 'next-i18next'

import { Parking } from '@/service'
import Link from '@/components/common/Link'
import ParkingMarker from '@/assets/parking-marker.svg?inline'
import ParkingTags from '@/components/parking/ParkingTags'
import PriceTag from '@/components/parkings/PriceTag'

import { mediaDown, mediaUp } from '@/styles/media'
import { focusVisible } from '@/styles/mixins'
import { border, colors, transitions } from '@/styles/theme'
import { H3, Text2 } from '@/styles/typography'
import { getParkingAddress } from '@/utils/getParkingAddress'
import { getParkingLabel } from '@/utils/getParkingLabel'

interface Props {
  parking: Parking
  big?: boolean
}

function ParkingTile(props: Props) {
  const { parking, big } = props
  const { t } = useTranslation('parkings')

  return (
    <Link
      href={{
        pathname: '/parkings/[city]/[slug]',
        query: { city: parking.cityUrlSlug, slug: parking.urlSlug }
      }}
      passHref
    >
      <a css={styles.wrapper} data-test-id={`parkings-tile-${parking.urlSlug}`}>
        <div css={[styles.imageWrapper, big && styles.imageWrapperBig]}>
          {parking.mainPictureUrl && (
            <img src={parking.mainPictureUrl} alt={parking.shortDisplayName} />
          )}
        </div>
        {parking.price?.pricePerHour && (
          <PriceTag price={parking.price.pricePerHour} />
        )}
        <ParkingTags parking={parking} short />
        <div css={styles.infoWrapper}>
          <div css={styles.iconWrapper}>
            <ParkingMarker />
          </div>
          <div css={styles.parkingInfo}>
            <H3 css={styles.title}>{getParkingLabel(parking, t)}</H3>
            <Text2 css={styles.description}>
              {getParkingAddress(parking, t)}
            </Text2>
          </div>
        </div>
      </a>
    </Link>
  )
}

const styles = {
  wrapper: css`
    ${focusVisible};
    display: block;
    background-color: ${colors.paleGrey};
    border-radius: 16px;
    text-decoration: none;
    width: 100%;
    max-width: 510px;
    transition: box-shadow ${transitions.base};
    margin: 0 auto;
    transform: translateZ(0);

    ${mediaUp('lg')} {
      border-radius: 28px;
    }

    &:hover {
      box-shadow: ${border.shadow};

      img {
        transform: scale(1.05);
      }
    }
  `,
  imageWrapper: css`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 127px;
    transform: translateZ(0);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    ${mediaUp('lg')} {
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;
    }

    img {
      object-fit: cover;
      transition: transform ${transitions.base}, opacity ${transitions.image};
      width: 100%;
      height: 100%;
    }

    ${mediaUp('lg')} {
      height: 209px;
    }
  `,
  imageWrapperBig: css`
    ${mediaUp('lg')} {
      height: 296px;
    }
  `,
  infoWrapper: css`
    display: flex;
    align-items: center;
    padding: 14px;

    ${mediaUp('lg')} {
      padding: 30px;
    }
  `,
  iconWrapper: css`
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 14px;
    background-color: ${colors.white};
    margin-inline-end: 20px;
    flex-shrink: 0;

    ${mediaDown('lg')} {
      display: none;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: ${colors.twilightBlue};
    }
  `,
  parkingInfo: css`
    max-width: 100%;

    ${mediaUp('lg')} {
      max-width: calc(100% - 78px);
    }
  `,
  title: css`
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    ${mediaUp('lg')} {
      margin-bottom: 8px;
    }
  `,
  description: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  `
}

export default ParkingTile
